<template>
  <div class="carousel">
    <Carousel>
      <Slide v-for="slide in 10" :key="slide" :transition="300">
        <div style="width: 50px; background-color: purple">{{ slide }}</div>
      </Slide>

      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script lang="ts">
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
  name: "Tab1",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
<style>
.carousel {
  min-height: 100vh;
}
</style>
